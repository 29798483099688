import { ICommunity, ILocation, answerToGqlAnswer } from "@sp-crm/core";
import { Community, CommunityCardBasicsFragment } from "../../generated/graphql";
import { ListableCommunity } from "./props";

type Parts = Pick<Community, "id" | "name" | "latitude" | "longitude" | "contractStatus">;
export const gqlCommunuityToMappableCommunity = (input: Parts): ListableCommunity => {
    return {
        address: "",
        contacts: [],
        primaryContacts: [],
        contractStatus: input.contractStatus,
        id: input.id,
        lat: input.latitude,
        lng: input.longitude,
        mostRecentPlacement: null,
        name: input.name,
        phone: "",
        placements: 0,
        rating: 0,
        mainPhone: "",
        summary: "",
        thumbnail: "",
        url: "",
        showInSearchResults: true,
        city: "",
        state: "",
        answers: [],
        referralCount: 0,
    };
};

export const communityToListableCommunity = (input: ICommunity): ListableCommunity => {
    const result: ListableCommunity = {
        address: input.address,
        contacts: input.contactEntities,
        primaryContacts: input.primaryContactEntities,
        contractStatus: input.contractStatus,
        id: input.id,
        lat: input?.location?.loc?.[1].toString(),
        lng: input?.location?.loc?.[0].toString(),
        mostRecentPlacement: null,
        name: input.name,
        phone: input.mainPhone,
        placements: 0,
        rating: input.rating,
        mainPhone: input.mainPhone,
        summary: input.summary,
        thumbnail: input.thumbnailUrl,
        url: input.website,
        showInSearchResults: input.showInSearchResults,
        city: input.city,
        state: input.state,
        zip: input.zip,
        answers: (input.answers || []).map(a => answerToGqlAnswer(a)),
        referralCount: 0,
        email: input.email,
        county: input.county,
    };
    return result;
};

export const locationFor = (l: ListableCommunity): ILocation | null => {
    if (
        !l ||
        l.lat === undefined ||
        l.lng === undefined ||
        l.lng === null ||
        l.lat === null ||
        isNaN(parseFloat(l.lng)) ||
        isNaN(parseFloat(l.lat))
    )
        return null;
    return {
        loc: [parseFloat(l.lng), parseFloat(l.lat)],
    };
};

export const communityCardContactToListableCommunityContact = (
    input: CommunityCardBasicsFragment["contacts"][0],
): ListableCommunity["contacts"][0] => {
    const result: ListableCommunity["contacts"][0] = {};

    for (const key of Object.keys(input)) {
        const typedKey = key as keyof typeof input;
        switch (typedKey) {
            case "name":
            case "cellPhone":
            case "email1":
            case "phone1":
            case "role":
                result[typedKey] = input[typedKey];
                break;
            case "__typename":
                /* noop */
                break;
            default: {
                const exhaustiveCheck: never = typedKey;
                console.warn(
                    `Unhandled key ${exhaustiveCheck} in communityCardContactToListableCommunityContact`,
                );
                break;
            }
        }
    }

    return result;
};

export const cardBasicsToListableCommunity = (
    input: CommunityCardBasicsFragment,
): ListableCommunity => {
    const result: ListableCommunity = {
        showInSearchResults: true,
        address: input.address,
        contacts: input.contacts.map(c =>
            communityCardContactToListableCommunityContact(c),
        ),
        primaryContacts: input.primaryContacts.map(c =>
            communityCardContactToListableCommunityContact(c),
        ),
        contractStatus: input.contractStatus,
        id: input.id,
        lat: input.latitude,
        lng: input.longitude,
        mostRecentPlacement: null,
        name: input.name,
        phone: input.mainPhone,
        placements: 0,
        rating: input.rating,
        mainPhone: input.mainPhone,
        summary: input.summary,
        thumbnail: input.thumbnailUrl,
        url: input.website,
        city: input.city,
        state: input.state,
        zip: input.zip,
        answers: input.answers,
        unitsOrCapacity: input.unitsOrCapacity,
        contractExpiresDate: input.contractExpiresDate,
        contractIssuedDate: input.contractIssuedDate,
        contractNotes: input.contractNotes,
        gender: input.gender,
        paymentFee: input.paymentFee,
        paymentPercentage: input.paymentPercentage,
        licenseStatus: input.licenseStatus,
        monthlyPrice: input.monthlyPrice?.toString(),
        priceHighEnd: input.priceHighEnd?.toString(),
        feeType: input.feeType,
        secondPersonFee: input.secondPersonFee,
        petDeposit: input.petDeposit,
        moveInDeposit: input.moveInDeposit?.toString(),
        priceDetails: input.priceDetails,
        caregiverRatio: input.caregiverRatio,
        updatedAt: input.updatedAt,
        dateAdded: input.dateAdded,
        regionId: input.regionId,
        referralCount: 0,
        licenses: input.licenses,
        email: input.email,
        county: input.county,
    };
    return result;
};
