import * as React from "react";

const computeClassName = (...classes: string[]) => {
    return classes ? classes.join(" ") : "";
};

type RowTypes = "flex";
type RowJustification = "space-between";
type RowAlignment = "bottom";

interface RowProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    children?: any;
    className?: string;
    type?: RowTypes;
    justify?: RowJustification;
    align?: RowAlignment;
    id?: string;
}

export const Row: React.FC<RowProps> = (props: RowProps) => {
    const gridClasses = [
        props.type ? `sp-grid--row-type-${props.type}` : "",
        props.justify ? `sp-grid--row-justify-${props.justify}` : "",
        props.align ? `sp-grid--row-align-${props.align}` : "",
    ].join(" ");
    return (
        <div
            className={computeClassName("sp-grid--row", gridClasses, props.className)}
            id={props.id}>
            {props.children}
        </div>
    );
};

interface ColProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    children?: any;
    className?: string;
}

export const Col: React.FC<RowProps> = (props: ColProps) => {
    return (
        <div className={computeClassName("sp-grid--col", props.className)}>
            {props.children}
        </div>
    );
};

interface NgGridProps {
    children: React.ReactNode;
}

interface NgGridComponent extends React.FC<NgGridProps> {
    Column: React.FC<NgGridProps>;
}

const NgGridColumn: React.FC<NgGridProps> = props => {
    return <div className="grid gap-2 lg:gap-4 auto-rows-min">{props.children}</div>;
};

export const NgGrid: NgGridComponent = Object.assign(
    (props: NgGridProps) => {
        return <div className="grid lg:grid-cols-2 gap-2 lg:gap-4">{props.children}</div>;
    },
    { Column: NgGridColumn },
);
