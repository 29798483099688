import {
    AnswerFormattingOptions,
    IBridgeFieldMetadata,
    IQuestion,
    NaryFormat,
    QuestionType,
} from "@sp-crm/core";
import React, { ReactNode } from "react";
import {
    CommunityCardBasicsFragment,
    GetLayoutSectionQuery,
    GetLayoutSectionsQuery,
} from "../../generated/graphql";

export type LayoutItemResult =
    GetLayoutSectionQuery["getLayoutSection"]["layoutItems"][0];
export type LayoutSectionResult =
    GetLayoutSectionsQuery["getLayoutSections"]["layoutSections"][0];
type Answer = CommunityCardBasicsFragment["answers"][0];

interface LayoutItemsProps<T> {
    questions: IQuestion[];
    getAnswers: (entity: T) => Answer[];
    renderAnswer: (
        question: IQuestion,
        answer: Answer,
        formattingOptions: AnswerFormattingOptions,
    ) => ReactNode;
    renderStandardField: (
        entity: T,
        fieldName: string,
        fieldMetadata: IBridgeFieldMetadata,
    ) => ReactNode;
    children: (
        layoutItems: LayoutItemResult[],
        getTitle: (layoutItem: LayoutItemResult) => string,
        renderValue: (entity: T, layoutItem: LayoutItemResult) => ReactNode,
    ) => ReactNode;
    layoutItems: LayoutItemResult[];
    fieldMetadataList: IBridgeFieldMetadata[];
}

export const LayoutItems = <T,>(props: LayoutItemsProps<T>) => {
    const {
        getAnswers,
        renderAnswer,
        renderStandardField,
        children,
        fieldMetadataList,
        questions,
    } = props;

    const layoutItems: LayoutItemResult[] = props.layoutItems || [];

    const computeTitle = (layoutItem: LayoutItemResult): string => {
        if (layoutItem.questionId) {
            const question = questions.find(q => q.id === layoutItem.questionId);

            return question?.title || "";
        }

        if (layoutItem.entityFieldName) {
            const fieldMetadata = fieldMetadataList.find(
                f => f.name === layoutItem.entityFieldName,
            );
            if (fieldMetadata) {
                return fieldMetadata.title;
            }
        }

        return "";
    };

    const answerNotEmpty = (
        question: IQuestion,
        answer: Answer,
        questions: IQuestion[],
        answers: Answer[],
    ): boolean => {
        if (!answer || !question || !question.enabled(questions, answers)) {
            return false;
        }

        switch (question.questionType) {
            case QuestionType.binary:
                return answer.booleanAnswer === true;
            case QuestionType.currency:
            case QuestionType.number:
                return typeof answer.numberAnswer === "number";
            case QuestionType.date:
                return typeof answer.numberAnswer === "number" || !!answer.dateAnswer;
            case QuestionType.nary:
                return Array.isArray(answer.selections) && answer.selections.length > 0;
            case QuestionType.range:
                return (
                    (typeof answer.numberAnswer === "number" ||
                        typeof answer.numberAnswerHigh === "number") &&
                    (!!answer.numberAnswer || !!answer.numberAnswerHigh)
                );
            case QuestionType.text:
                return typeof answer.textAnswer === "string";
            case QuestionType.user:
                return !!answer.idReferenceAnswer;
            case QuestionType.phone:
                return (
                    typeof answer.textAnswer === "string" &&
                    answer.textAnswer.trim().length > 0
                );
            default:
                console.warn('Unhandled question type: "' + question.questionType + '"');
                return false;
        }
    };

    const computeValue = (entity: T, layoutItem: LayoutItemResult): ReactNode => {
        if (layoutItem.questionId) {
            const question = questions.find(q => q.id === layoutItem.questionId);
            const answers = getAnswers(entity) || [];
            const answer = answers.find(a => a.questionId === layoutItem.questionId);

            return answerNotEmpty(question, answer, questions, answers)
                ? renderAnswer(
                      question,
                      answer,
                      layoutItem.naryFormat === NaryFormat.OnePerLine
                          ? { naryDelimiter: "\n" }
                          : { naryDelimiter: "; " },
                  )
                : null;
        }

        if (layoutItem.entityFieldName) {
            const fieldMetadata = fieldMetadataList.find(
                f => f.name === layoutItem.entityFieldName,
            );
            if (fieldMetadata) {
                return renderStandardField(
                    entity,
                    layoutItem.entityFieldName,
                    fieldMetadata,
                );
            }
        }

        return null;
    };

    return (
        <>
            {layoutItems.length > 0
                ? children(layoutItems, computeTitle, computeValue)
                : null}
        </>
    );
};
