import { HomeIcon } from "@heroicons/react/20/solid";
import {
    CommunityId,
    FileRenderInfo,
    formatEntityValue,
    formatShortAddress,
    IBridgeFieldMetadata,
    ILocation,
    IQuestion,
    isValidLocation,
    ITenantSettings,
    LayoutSectionKey,
    Localization,
    parseLocation,
    printableDistance,
    renderAnswer,
    SectionRenderBehaviorType,
} from "@sp-crm/core";
import { defaultLinkStyle } from "components/ui/link";
import { computeDirectionsUrl } from "helpers/address";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useProductName } from "store/selectors/branding";
import { breakNewlines } from "util/text";
import { displayableUri, isUrl, sanitizeUri } from "util/uri";
import { ClientCommunitiesFragment } from "../../../../generated/graphql";
import { Icon } from "../../../icon";
import { LayoutItems, LayoutSectionResult } from "../../../layout/layout-items";
import { PhotoViewer } from "../../../shared/multiple-photo-upload";
import { CommunityComparisonTableCommunity } from "./community-comparison-table";

interface RowUriProps {
    uri: string;
    label: string;
}

const classNames = {
    row: "border-b border-gray-400",
    column: "min-w-80 max-w-xl align-top p-4 even:bg-white odd:bg-brand-100",
};

const RowUri: React.FC<RowUriProps> = props => {
    const { uri, label } = props;

    return uri ? (
        <div className="mt-1">
            <p className="text-xs font-semibold">{label}</p>
            <a
                className="text-brand-600 hover:underline"
                href={sanitizeUri(uri)}
                target="_blank"
                rel="noreferrer">
                {displayableUri(uri, 25)}
            </a>
        </div>
    ) : null;
};

interface CommunityRowHeaderProps {
    layoutSection: LayoutSectionResult;
}

const CommunityRowHeader: React.FC<CommunityRowHeaderProps> = props => {
    const { layoutSection } = props;

    return (
        <td className="align-top p-4 bg-brand-900 min-w-48">
            <div className="flex">
                {layoutSection.icon ? (
                    <div className="w-6 mr-1">
                        <Icon name={layoutSection.icon} />
                    </div>
                ) : null}
                <p className="text-base font-medium text-white">{layoutSection.title}</p>
            </div>
        </td>
    );
};

interface CommunityLicenseRecordsItemProps {
    community: CommunityComparisonTableCommunity;
}

const CommunityLicenseRecordsItem: React.FC<CommunityLicenseRecordsItemProps> = props => {
    const { community } = props;

    const licensesWithLinks = useMemo(() => {
        return (community.licenses || []).filter(l => isUrl(l.href));
    }, [community.licenses]);

    if (licensesWithLinks.length === 0) {
        return null;
    }

    return (
        <ul>
            {licensesWithLinks.map((license, index) => {
                return (
                    <li key={index}>
                        <a
                            className={`truncate ${defaultLinkStyle}`}
                            target="_blank"
                            rel="noreferrer"
                            href={sanitizeUri(license.href)}>
                            Record lookup{" "}
                            {license.licenseNumber ? `#${license.licenseNumber}` : ""}
                        </a>
                    </li>
                );
            })}
        </ul>
    );
};

interface CommunityComparisonTableRowRenderProps {
    layoutSection: LayoutSectionResult;
    communities: CommunityComparisonTableCommunity[];
    getPublicNotes: (communityId: CommunityId) => string | null;
}

interface LayoutItemsRowProps extends CommunityComparisonTableRowRenderProps {
    layoutSection: LayoutSectionResult;
    fieldMetadataList: IBridgeFieldMetadata[];
    questions: IQuestion[];
}

const LayoutItemsRow: React.FC<LayoutItemsRowProps> = props => {
    const { communities, layoutSection, fieldMetadataList, questions } = props;

    const productName = useProductName();

    const layoutItems = layoutSection.layoutItems;

    return (
        <LayoutItems
            layoutItems={layoutItems}
            fieldMetadataList={fieldMetadataList}
            questions={questions}
            getAnswers={(
                c: ClientCommunitiesFragment["clientCommunities"][0]["community"],
            ) => c.answers}
            renderAnswer={(q, a, fo) => <>{breakNewlines(renderAnswer(q, a, {}, fo))}</>}
            renderStandardField={(community, fieldName, fieldDefinition) => {
                if (fieldName === "__computed__licenseRecords") {
                    return community.licenses?.some(l => isUrl(l.href)) ? (
                        <CommunityLicenseRecordsItem community={community} />
                    ) : null;
                }

                const formatted = formatEntityValue(
                    community,
                    fieldName,
                    fieldDefinition,
                    {
                        regions: [],
                        users: {},
                        productName,
                    },
                );

                return formatted ? <>{formatted}</> : null;
            }}>
            {(layoutItems, getTitle, renderValue) => {
                const shouldShow = layoutItems.some(layoutItem =>
                    communities.some(community => !!renderValue(community, layoutItem)),
                );

                return shouldShow ? (
                    <tr className={classNames.row}>
                        <CommunityRowHeader layoutSection={layoutSection} />
                        {communities.map(community => (
                            <td className={classNames.column} key={community.id}>
                                {layoutItems.map(layoutItem => {
                                    const value = renderValue(community, layoutItem);
                                    return value ? (
                                        <div className="mb-2" key={layoutItem.id}>
                                            <p className="text-sm font-semibold">
                                                {getTitle(layoutItem)}
                                            </p>
                                            <p className="text-sm">{value}</p>
                                        </div>
                                    ) : null;
                                })}
                            </td>
                        ))}
                    </tr>
                ) : null;
            }}
        </LayoutItems>
    );
};

const CommunityNameRow: React.FC<CustomRowProps> = props => {
    const { layoutSection, communities, clickableCommunities, tenantSettings } = props;

    return (
        <tr className={classNames.row}>
            <CommunityRowHeader layoutSection={layoutSection} />
            {communities.map(community => {
                const addressLine1 = community.address;
                const addressLine2 = formatShortAddress(community);
                return (
                    <td className={classNames.column} key={community.id}>
                        {community.thumbnailUrl ? (
                            <img className="w-32 h-32" src={community.thumbnailUrl} />
                        ) : (
                            <HomeIcon className="bg-gray-200 text-gray-50 w-32 h-32" />
                        )}
                        <div className="my-1">
                            {clickableCommunities ? (
                                <Link
                                    className={"text-lg hover:underline"}
                                    to={`/communities/show/${community.id}`}>
                                    {community.name}
                                </Link>
                            ) : (
                                <p className="text-lg">{community.name}</p>
                            )}
                        </div>
                        {tenantSettings["CommunityComparisonCommunityRow.showAddress"] ? (
                            <>
                                <p className="text-sm">{addressLine1}</p>
                                <p className="text-sm">{addressLine2}</p>
                                {addressLine1 && addressLine2 ? (
                                    <div>
                                        <a
                                            href={computeDirectionsUrl(
                                                addressLine1,
                                                addressLine2,
                                            )}
                                            target="_blank"
                                            rel="noreferrer"
                                            className={`${defaultLinkStyle} text-sm`}>
                                            Directions
                                        </a>
                                    </div>
                                ) : null}
                            </>
                        ) : null}
                        {tenantSettings["CommunityComparisonCommunityRow.showPhone"] ? (
                            <p className="text-sm">{community.mainPhone}</p>
                        ) : null}
                        {tenantSettings["CommunityComparisonCommunityRow.showWebsite"] ? (
                            <p className="text-sm">
                                <RowUri label="Website" uri={community.website} />
                            </p>
                        ) : null}
                        {tenantSettings[
                            "CommunityComparisonCommunityRow.showVirtualTour"
                        ] ? (
                            <p className="text-sm">
                                <RowUri
                                    label="Virtual tour"
                                    uri={community.virtualTourURL}
                                />
                            </p>
                        ) : null}
                    </td>
                );
            })}
        </tr>
    );
};

interface CommunityContactColumnProps {
    community: ClientCommunitiesFragment["clientCommunities"][0]["community"];
}

const CommunityContactColumn: React.FC<CommunityContactColumnProps> = props => {
    const { community } = props;

    const primaryContacts = community.primaryContacts || [];
    return (
        <td className={classNames.column}>
            <ul>
                {primaryContacts.map((contact, index) => (
                    <li key={index} className="mb-2">
                        <div>
                            <p className="text-sm font-semibold">{contact.name || ""}</p>
                            {contact.phone1 ? (
                                <p className="text-sm">{contact.phone1 + " (direct)"}</p>
                            ) : null}
                            {contact.cellPhone ? (
                                <p className="text-sm">{contact.cellPhone + " (cell)"}</p>
                            ) : null}
                            <p className="text-sm">{contact.email1}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </td>
    );
};

const CommunityContactRow: React.FC<CommunityComparisonTableRowRenderProps> = props => {
    const { layoutSection, communities } = props;

    const shouldShow = communities.some(
        community => (community.primaryContacts || []).length > 0,
    );

    return shouldShow ? (
        <tr className={classNames.row}>
            <CommunityRowHeader layoutSection={layoutSection} />
            {communities.map(community => (
                <CommunityContactColumn key={community.id} community={community} />
            ))}
        </tr>
    ) : null;
};

interface CommunityDistanceColumnProps {
    community: ClientCommunitiesFragment["clientCommunities"][0]["community"];
    baseLocation: ILocation | null;
    locale: Localization;
}

const CommunityDistanceColumn: React.FC<CommunityDistanceColumnProps> = props => {
    const { community, baseLocation, locale } = props;

    const communityLocation = parseLocation(community.latitude, community.longitude);

    return (
        <td className={classNames.column}>
            {communityLocation.hasValue ? (
                <p>{printableDistance(locale, baseLocation, communityLocation.get())}</p>
            ) : null}
        </td>
    );
};

const CommunityDistanceRow: React.FC<CustomRowProps> = props => {
    const { layoutSection, communities, locale, baseLocation } = props;

    const shouldShow =
        isValidLocation(baseLocation) &&
        communities.some(
            community => parseLocation(community.latitude, community.longitude).hasValue,
        );

    return shouldShow ? (
        <tr className={classNames.row}>
            <CommunityRowHeader layoutSection={layoutSection} />
            {communities.map(community => (
                <CommunityDistanceColumn
                    key={community.id}
                    community={community}
                    baseLocation={baseLocation}
                    locale={locale}
                />
            ))}
        </tr>
    ) : null;
};

const CommunityNotesRow: React.FC<CommunityComparisonTableRowRenderProps> = props => {
    const { layoutSection, communities, getPublicNotes } = props;

    const shouldShow = communities.some(community => !!getPublicNotes(community.id));

    return shouldShow ? (
        <tr className={classNames.row}>
            <CommunityRowHeader layoutSection={layoutSection} />
            {communities.map(community => (
                <td className={classNames.column} key={community.id}>
                    <p>{getPublicNotes(community.id) || ""}</p>
                </td>
            ))}
        </tr>
    ) : null;
};

const CommunityPhotosRow: React.FC<CommunityComparisonTableRowRenderProps> = props => {
    const { layoutSection, communities } = props;

    const shouldShow = communities.some(
        community => !!(community.photoEntities && community.photoEntities.length > 0),
    );

    const imageRenderInfo: FileRenderInfo = {
        fileGroupIcon: "55_photos",
        fileGroupHeading: layoutSection.title,
    };

    return shouldShow ? (
        <tr className={classNames.row}>
            <CommunityRowHeader layoutSection={layoutSection} />
            {communities.map(community => (
                <td className={classNames.column} key={community.id}>
                    {community.photoEntities && community.photoEntities.length > 0 ? (
                        <PhotoViewer
                            photos={community.photoEntities}
                            imageRenderInfo={imageRenderInfo}
                            title={community.name}
                            includeButton={true}
                        />
                    ) : null}
                </td>
            ))}
        </tr>
    ) : null;
};

type CustomRowProps = CommunityComparisonTableRowRenderProps & {
    clickableCommunities: boolean;
    baseLocation: ILocation | null;
    locale: Localization;
    tenantSettings: ITenantSettings;
};

const CustomRow: React.FC<CustomRowProps> = props => {
    const { layoutSection } = props;

    switch (layoutSection.sectionKey) {
        case LayoutSectionKey.CommunityComparisonCommunityRow:
            return <CommunityNameRow {...props} />;
        case LayoutSectionKey.CommunityComparisonPrimaryContactRow:
            return <CommunityContactRow {...props} />;
        case LayoutSectionKey.CommunityComparisonDistanceRow:
            return <CommunityDistanceRow {...props} />;
        case LayoutSectionKey.CommunityComparisonNotesRow:
            return <CommunityNotesRow {...props} />;
        case LayoutSectionKey.CommunityComparisonPhotosRow:
            return <CommunityPhotosRow {...props} />;
        default:
            throw new Error(
                `Unknown section to render in community comparison ${layoutSection.sectionKey}`,
            );
    }
};

const SinglePropertyRow: React.FC<CommunityComparisonTableRowRenderProps> = props => {
    const { layoutSection, communities } = props;

    const communityPropertyName =
        layoutSection.singlePropertyName as keyof ClientCommunitiesFragment["clientCommunities"][0]["community"];

    const shouldShow = communities.some(community => !!community[communityPropertyName]);

    return shouldShow ? (
        <tr className={classNames.row}>
            <CommunityRowHeader layoutSection={layoutSection} />
            {communities.map(community => (
                <td className={classNames.column} key={community.id}>
                    <p>{community[communityPropertyName] || ""}</p>
                </td>
            ))}
        </tr>
    ) : null;
};

interface CommunityComparisonTableRowProps {
    layoutSection: LayoutSectionResult;
    communities: CommunityComparisonTableCommunity[];
    getPublicNotes: (communityId: CommunityId) => string | null;
    clickableCommunities: boolean;
    baseLocation: ILocation | null;
    locale: Localization;
    fieldMetadataList: IBridgeFieldMetadata[];
    questions: IQuestion[];
    tenantSettings: ITenantSettings;
}

export const CommunityComparisonTableRow: React.FC<
    CommunityComparisonTableRowProps
> = props => {
    const {
        layoutSection,
        communities,
        getPublicNotes,
        clickableCommunities,
        locale,
        baseLocation,
        fieldMetadataList,
        tenantSettings,
        questions,
    } = props;

    if (!layoutSection.visible) {
        return null;
    }

    switch (layoutSection.renderBehavior) {
        case SectionRenderBehaviorType.LayoutItems:
            return (
                <LayoutItemsRow
                    layoutSection={layoutSection}
                    communities={communities}
                    getPublicNotes={getPublicNotes}
                    fieldMetadataList={fieldMetadataList}
                    questions={questions}
                />
            );
        case SectionRenderBehaviorType.Custom:
            return (
                <CustomRow
                    layoutSection={layoutSection}
                    communities={communities}
                    getPublicNotes={getPublicNotes}
                    clickableCommunities={clickableCommunities}
                    locale={locale}
                    baseLocation={baseLocation}
                    tenantSettings={tenantSettings}
                />
            );
        case SectionRenderBehaviorType.SingleProperty:
            return (
                <SinglePropertyRow
                    layoutSection={layoutSection}
                    communities={communities}
                    getPublicNotes={getPublicNotes}
                />
            );
        default:
            throw new Error(
                `Unknown render behavior in Community Comparison Table: ${layoutSection.renderBehavior}`,
            );
    }
};
