import {
    CommunityId,
    IBridgeFieldMetadata,
    ILocation,
    IQuestion,
    ITenantSettings,
    Localization,
} from "@sp-crm/core";
import React from "react";
import { ClientCommunitiesFragment } from "../../../../generated/graphql";
import { LayoutSectionResult } from "../../../layout/layout-items";
import { CommunityComparisonTableRow } from "./community-comparison-table-row";

export type CommunityComparisonTableCommunity =
    ClientCommunitiesFragment["clientCommunities"][0]["community"];

interface CommunityComparisonTableProps {
    layoutSections: LayoutSectionResult[];
    communities: CommunityComparisonTableCommunity[];
    getPublicNotes: (communityId: CommunityId) => string | null;
    clickableCommunities: boolean;
    baseLocation: ILocation | null;
    locale: Localization;
    fieldMetadataList: IBridgeFieldMetadata[];
    questions: IQuestion[];
    tenantSettings: ITenantSettings;
}

export const CommunityComparisonTable: React.FC<
    CommunityComparisonTableProps
> = props => {
    const {
        layoutSections,
        communities,
        getPublicNotes,
        clickableCommunities,
        baseLocation,
        locale,
        fieldMetadataList,
        tenantSettings,
        questions,
    } = props;
    return (
        <table>
            <tbody>
                {layoutSections.map(layoutSection => (
                    <CommunityComparisonTableRow
                        key={layoutSection.id}
                        layoutSection={layoutSection}
                        communities={communities}
                        getPublicNotes={getPublicNotes}
                        clickableCommunities={clickableCommunities}
                        baseLocation={baseLocation}
                        locale={locale}
                        fieldMetadataList={fieldMetadataList}
                        questions={questions}
                        tenantSettings={tenantSettings}
                    />
                ))}
            </tbody>
        </table>
    );
};
