import { ILocation } from "@sp-crm/core";
import { MarkerIconColor } from "components/map/types";
import "maplibre-gl/dist/maplibre-gl.css";
import * as React from "react";
import { MapboxEvent, Marker } from "react-map-gl";
import { assetRoot } from "util/version";
import { MapMarkerId } from "./types";

const lng = (loc: ILocation) => loc.loc[0];
const lat = (loc: ILocation) => loc.loc[1];

export const getMarkerIconUrl = (iconColor: MarkerIconColor): string => {
    switch (iconColor) {
        case "orange":
        case "green":
        case "violet":
            return `${assetRoot}/static/images/marker-icon-${iconColor}-2x.png`;
        case "blue":
            return assetRoot + "/static/images/marker-icon-2x.png";
        default: {
            const exhaustiveCheck: never = iconColor;
            throw new Error(`Unknown icon color: ${exhaustiveCheck}`);
        }
    }
};

export const NextgenMapMarker: React.FC<{
    id: MapMarkerId;
    isHovered: boolean;
    onSelected?: () => void;
    iconType: MarkerIconColor;
    location: ILocation | null;
}> = props => {
    const { isHovered, onSelected, iconType, location } = props;
    const onClick = React.useCallback(
        (e: MapboxEvent<MouseEvent>) => {
            e.originalEvent.stopPropagation();
            if (onSelected) {
                onSelected();
            }
        },
        [onSelected],
    );
    if (!location) {
        return null;
    }

    return (
        <Marker
            longitude={lng(location)}
            latitude={lat(location)}
            anchor="bottom"
            onClick={onClick}>
            <img
                src={getMarkerIconUrl(iconType)}
                className={isHovered ? "w-10 h-16" : "w-6 h-10"}
            />
        </Marker>
    );
};
